/* istanbul ignore file */
import React from "react";
import PropTypes from "prop-types";

import TextField from "@mui/material/TextField";
import { isValidYear } from "../../utils/StringUtils";

const YearUI = ({ style, valid, callback }) => {
  const [value, setValue] = React.useState("");

  const showErrorState = valid ? false : !isValidYear(value);

  const _setValue = (val) => {
    setValue(val);
    callback(val);
  };

  return (
    <TextField
      error={showErrorState}
      variant="outlined"
      sx={style}
      placeholder="YYYY"
      fullWidth
      value={value}
      onChange={(event) => {
        const typed = event.target.value;
        if (!typed) {
          _setValue("");
        } else if (typed.length <= 4 && /^[0-9]+$/.test(typed)) {
          _setValue(typed);
        }
      }}
    />
  );
};

export default YearUI;

YearUI.propTypes = {
  style: PropTypes.shape().isRequired,
  callback: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
};
