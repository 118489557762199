const currentTimeIsIn = (start, end) => {
  let yes = Boolean(start || end);
  const now = Date.now();
  if (start) {
    const st = new Date(start);
    yes = st.toString() !== "Invalid Date" && now > st;
  }

  if (yes && end) {
    const et = new Date(end);
    yes = et.toString() !== "Invalid Date" && et > now;
  }

  return yes;
};

const jgiStartDate = () => "1997-01-01";
const paddingTo2 = (val) => String(val).padStart(2, "0"); // padding with '0'

const currentYYYYMMDD = () => {
  const now = new Date();
  return `${now.getFullYear()}-${paddingTo2(now.getMonth() + 1)}-${paddingTo2(
    now.getDate()
  )}`;
};

const toYYYYMMDD = (value, type = "from") => {
  if (value) {
    const dObj = new Date(value);
    const year = dObj.getFullYear();
    const month = paddingTo2(dObj.getMonth() + 1);
    const date = paddingTo2(dObj.getDate());
    return `${year}-${month}-${date}`;
  }

  return type === "from" ? jgiStartDate() : currentYYYYMMDD();
};

export { currentTimeIsIn, toYYYYMMDD, currentYYYYMMDD, jgiStartDate };
