/* istanbul ignore file */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/InputBase";
import Grid from "@mui/material/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import ButtonMui from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import LinearProgress from "@mui/material/LinearProgress";

import ErrorIcon from "@mui/icons-material/Error";

import { getNativeButton } from "../Button/Button";
import JDPHelpIcon from "../UI/Icons/JDPHelpIcon";
import filterClasses from "../FilterContainer/Filters/Filters.module.css";
import { HREF_DOC_ADVANCED_SEARCH } from "../../utils/URLConstants";
import PulldownMenu from "../UI/PulldownMenu/PulldownMenu";
import { advancedSearchKeys } from "../../utils/HttpClientProvider";
import { useSearchParams } from "../../hooks/useSearchParams";

import Tooltip, { ICON_NONE } from "../UI/Tooltip/Tooltip";
import YearUI from "./YearUI";
import DateUI from "./DateUI";
import ValueUI from "./ValueUI";

import { isValidDate, isValidYear } from "../../utils/StringUtils";
import {
  jgiStartDate,
  currentYYYYMMDD,
  toYYYYMMDD,
} from "../../utils/TimeUtils";

import { AQBFieldHelperText } from "../../utils/LDFFUtils";

import theme from "../../themes/theme.module.css";

const ADV_SEARCH_OP = ["AND", "OR", "NOT"];
const ALL_FIELDS = "All Fields";
const ERROR_MSG =
  "We were not able to process your query. Please check the type or the syntax of your query and try again.";

const AdvancedSearch = ({
  isLoading,
  error,
  open,
  onClose,
  honeycomb,
  topRect,
  ldClient,
  currentUser,
}) => {
  // JDP-2690 - date fields : will be initialized from BE list
  // eslint-disable-next-line no-unused-vars
  const [DATE_RANGE_FIELD_DESC, setDATE_RANGE_FIELD_DESC] = useState({
    year: [
      // "Proposal completion year",
      // "Proposal acceptance year",
      // "Publication year",
    ],
    date: [
      // "File added"
    ],
    all: [
      // "Proposal completion year",
      // "Proposal acceptance year",
      // "Publication year",
      // "File added",
    ],
  });

  const [searchMeta, setSearchMeta] = useState([{ value: "" }]);
  const [keyMap, setKeyMap] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [field2TitleMap, setField2TitleMap] = useState({});

  const history = useHistory();
  const { q } = useSearchParams();

  useEffect(() => {
    const promise = advancedSearchKeys();
    promise.then((resp) => {
      const kMap = {
        desc2name: {
          "All Fields": ALL_FIELDS,
        },
        name2desc: {
          "All Fields": ALL_FIELDS,
        },
      };
      resp?.forEach((d) => {
        const { description, name } = d;
        kMap.desc2name[description] = name;
        kMap.name2desc[name] = description;
      });

      // JDP-2690 - date fields
      // TODO: the date range field will from BE. Here we will collect them out and call setDATE_RANGE_FIELD_DESC()
      DATE_RANGE_FIELD_DESC.all.forEach((d) => {
        const name = d.replaceAll(" ", "_").toUpperCase();
        const description = d;
        kMap.desc2name[description] = name;
        kMap.name2desc[name] = description;
      });

      // console.log("[AQB]", kMap);
      setKeyMap(kMap);
    });

    honeycomb.sendUiInteractionSpan("adv-search-ui-opened");
    // pulldown placeholder text
    const aqbHelpTextMap = AQBFieldHelperText(ldClient);
    if (aqbHelpTextMap) {
      const previewer = currentUser?.email_address;
      const f2t = {};
      Object.keys(aqbHelpTextMap).forEach((k) => {
        // k is the display string (description in the JSON returned by BE /search/fields/) in the field pulldown
        const data = aqbHelpTextMap[k];
        if (previewer && previewer in data) {
          data.title = data[previewer];
        }
        const { title } = data;
        if (title) {
          f2t[k] = title;
        }
      });
      setField2TitleMap(f2t);
    }
  }, []);

  useEffect(() => {
    if (error) {
      setErrorMsg(ERROR_MSG);
    } else {
      setErrorMsg(null);
    }
  }, [error]);

  useEffect(() => {
    if (q && JSON.stringify(searchMeta[0]) === '{"value":""}' && keyMap) {
      const initd = {
        key: ALL_FIELDS,
        value: q,
      };
      setSearchMeta([initd]);
    }
  }, [q, keyMap]);

  // style for the 1st row read-only TextField
  const inputSizeStyle = {
    "& .MuiInputBase-input": {
      height: 12,
      padding: 1,
      border: `solid 1px ${theme.grey500}`,
      borderRadius: 1,
      backgroundColor: theme.grey100,
      color: theme.grey700,
    },
  };

  // style for the 1 or more search value TextField
  const inputStyle = {
    "& .MuiInputBase-input": {
      ...inputSizeStyle["& .MuiInputBase-input"],
    },
    color: theme.grey700,
    "& .MuiInputBase-input:hover": {
      border: `solid 1px ${theme.lake500}`,
    },
    "& .MuiInputBase-input:active": {
      border: `solid 1px ${theme.lake500}`,
    },
  };
  delete inputStyle["& .MuiInputBase-input"].backgroundColor;

  const iconStyle = {
    position: "relative",
    color: theme.lake500,
    cursor: "pointer",
    width: 26,
    height: 26,
    top: -2,
  };

  const updateValue = (index, value, type = "value") => {
    const meta = [...searchMeta];
    if (type === "value") {
      meta[index].value = value;
    } else if (type === "operator") {
      meta[index].operator = value;
    } else {
      // key
      const { value: curVal, key: curKey } = meta[index];
      if (
        typeof curVal === "object" &&
        !(
          DATE_RANGE_FIELD_DESC.year.includes(curKey) &&
          DATE_RANGE_FIELD_DESC.year.includes(value)
        )
      ) {
        // value is YEAR and old/new key are not both YEAR range - reset value
        meta[index].value = "";
      }
      meta[index].key = value;
    }
    setSearchMeta(meta);
  };

  const ICON_ADD = "ICON_ADD";
  const ICON_REMOVE = "ICON_REMOVE";
  const add_remove = (index, type) => {
    const meta = [...searchMeta];
    if (type === ICON_ADD) {
      meta.splice(index + 1, 0, { value: "" });
    } else {
      meta.splice(index, 1);
    }

    setSearchMeta(JSON.parse(JSON.stringify(meta)));
  };

  const validateDateRangeValue = (desc, value) => {
    // if a single date value is valid
    if (desc === "File added") {
      return isValidDate(value);
    }
    return isValidYear(value);
  };

  const isDateRangeHasValidValue = (d) => {
    // empty value is TRUE
    const { value, key } = d;
    if (typeof value === "object") {
      const { from, to } = value;
      const fromValid = !from || validateDateRangeValue(key, from);
      const toValid = !to || validateDateRangeValue(key, to);
      return fromValid && toValid;
    }
    return false;
  };

  const validDateRangeItems = (dateItems) => {
    // return dateRange items with valid values
    return dateItems.filter((d) => isDateRangeHasValidValue(d));
  };

  const learnMoreUI = (
    <JDPHelpIcon size="large">
      <Typography
        className={`${filterClasses.bodySmall} ${filterClasses.filterHelpText}`}
        noWrap
        style={{ position: "relative", top: 6 }}
      >
        <Link
          className={filterClasses.filterHelpLink}
          href={HREF_DOC_ADVANCED_SEARCH}
          target="_blank"
          data-testid="advSearchBuilderHelp"
          onClick={() => {
            honeycomb.sendUiInteractionSpan("adv-search-learn-more-clicked");
          }}
        >
          Learn more
        </Link>{" "}
        about value types and examples
      </Typography>
    </JDPHelpIcon>
  );

  const endColumnStyle = {
    width: searchMeta.length > 1 ? 60 : 30,
    position: "relative",
    left: -18,
  };

  const getValueUI = (idx, kname, value, style) => {
    const getLabel = (val) => (
      <Typography
        style={{
          position: "relative",
          top: 6,
          paddingRight: 6,
          paddingLeft: 10,
          fontSize: 14,
        }}
      >
        {val}
      </Typography>
    );
    const { key } = searchMeta[idx];
    let inputUI = null;
    // console.log(`[AdvancedSearch/getValueUI] value=${key}`);
    if (DATE_RANGE_FIELD_DESC.year.includes(key)) {
      inputUI = (
        <Grid container justifyContent="flex-end">
          <Grid item>{getLabel("FROM")}</Grid>
          <Grid item>
            <YearUI
              style={style}
              valid={isDateRangeHasValidValue(searchMeta[idx])}
              callback={(val) => {
                const meta = [...searchMeta];
                const { to } = meta[idx].value || {};
                updateValue(idx, { from: val, to });
              }}
            />
          </Grid>
          <Grid item>{getLabel("To")}</Grid>
          <Grid item>
            <YearUI
              style={style}
              valid={isDateRangeHasValidValue(searchMeta[idx])}
              callback={(val) => {
                const meta = [...searchMeta];
                const { from } = meta[idx].value || {};
                updateValue(idx, { from, to: val });
              }}
            />
          </Grid>
        </Grid>
      );
    } else if (DATE_RANGE_FIELD_DESC.date.includes(key)) {
      inputUI = (
        <Grid container justifyContent="flex-end">
          <Grid item>{getLabel("FROM")}</Grid>
          <Grid item>
            <DateUI
              style={style}
              valid={isDateRangeHasValidValue(searchMeta[idx])}
              callback={(val) => {
                const meta = [...searchMeta];
                const { to } = meta[idx].value || {};
                updateValue(idx, { from: val, to });
              }}
            />
          </Grid>
          <Grid item>{getLabel("TO")}</Grid>
          <Grid item>
            <DateUI
              style={style}
              valid={isDateRangeHasValidValue(searchMeta[idx])}
              callback={(val) => {
                const meta = [...searchMeta];
                const { from } = meta[idx].value || {};
                updateValue(idx, { from, to: val });
              }}
            />
          </Grid>
        </Grid>
      );
    } else {
      inputUI = (
        <ValueUI
          style={style}
          value={typeof value === "string" ? value : ""}
          disabled={!kname}
          callback={(val) => {
            updateValue(idx, val.trimStart());
          }}
          // placeholder={helpText}
        />
      );
    }

    const helpText = key ? field2TitleMap[key] : "This is the description area";
    return (
      <Grid
        container
        sx={{
          width: "auto",
        }}
      >
        <Grid item xs={12}>
          {inputUI}
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            style={{ color: "#949494", fontSize: 12 }}
          >
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: helpText,
              }}
            />
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const getSearchParamUI = () => {
    const adv_ops = ADV_SEARCH_OP.map((d) => ({ label: d }));

    return searchMeta.map((d, idx) => {
      const key = `adv-value-ui-${idx}`;
      const { value, operator } = d;
      const valueStyle = { ...inputStyle };
      if (value) {
        const istyle = {
          ...valueStyle["& .MuiInputBase-input"],
          border: `solid 1px ${theme.lake500}`,
        };
        valueStyle["& .MuiInputBase-input"] = istyle;
      }

      const fields = keyMap
        ? Object.keys(keyMap.desc2name)
            .sort()
            .map((desc) => ({ label: desc }))
        : null;

      return (
        <React.Fragment key={key}>
          {/* Column 1; optional; operator */}
          <Grid item lg={2} md={2} sm={2} xs={3}>
            {idx > 0 && (
              <PulldownMenu
                items={adv_ops}
                intVal={operator || ADV_SEARCH_OP[0]}
                onSelect={(val) => {
                  updateValue(idx, val, "operator");
                }}
                windowWidth={topRect?.width}
              />
            )}
          </Grid>

          {/* Column 2; field pulldown */}
          <Grid item lg={3} md={4} sm={4} xs={5}>
            {fields ? (
              <PulldownMenu
                intVal={d.key}
                items={fields}
                onSelect={(val) => {
                  updateValue(idx, val, "key");
                }}
                windowWidth={topRect?.width}
              />
            ) : (
              <LinearProgress />
            )}
          </Grid>

          {/* Column 3; value UI */}
          <Grid item lg={6} md={5} sm={5} xs={3}>
            {getValueUI(idx, d.key, value, d.key ? valueStyle : inputStyle)}
          </Grid>

          {/* Column 4; the add / remove icon */}
          <Grid item md={1} sm={1} xs={1}>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box sx={endColumnStyle}>
                <Box
                  sx={{
                    display: "flex",
                    position: "relative",
                    top: 4,
                    marginLeft: 1,
                  }}
                >
                  {searchMeta.length > 1 && (
                    <Tooltip
                      iconType={ICON_NONE}
                      title="Remove this line from builder"
                    >
                      <RemoveCircleIcon
                        sx={iconStyle}
                        onClick={() => {
                          add_remove(idx, ICON_REMOVE);
                        }}
                      />
                    </Tooltip>
                  )}
                  {(searchMeta.length === 1 ||
                    searchMeta.length - 1 === idx) && (
                    <Tooltip iconType={ICON_NONE} title="Add new builder line">
                      <AddCircleIcon
                        sx={
                          (typeof value === "object" &&
                            isDateRangeHasValidValue(d)) ||
                          (typeof value === "string" && value)
                            ? iconStyle
                            : { ...iconStyle, color: theme.grey500 }
                        }
                        onClick={
                          value
                            ? () => {
                                add_remove(idx, ICON_ADD);
                              }
                            : null
                        }
                      />
                    </Tooltip>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </React.Fragment>
      );
    });
  };

  const queryValue = () => {
    const qlist = [];
    if (
      keyMap &&
      DATE_RANGE_FIELD_DESC &&
      DATE_RANGE_FIELD_DESC.all &&
      DATE_RANGE_FIELD_DESC.year &&
      DATE_RANGE_FIELD_DESC.date
    ) {
      searchMeta.forEach((d, idx) => {
        const { value: dValue, key: dKey } = d;
        const operator = idx > 0 ? d.operator || ADV_SEARCH_OP[0] : "";
        const op = operator && ` ${operator} `;
        const keyName = keyMap.desc2name[dKey];

        if (!DATE_RANGE_FIELD_DESC.all.includes(dKey)) {
          const cleanedValue =
            dValue && typeof dValue === "string" ? dValue.trim() : null;
          if (cleanedValue) {
            const value =
              cleanedValue.indexOf(" ") > -1
                ? `(${cleanedValue})`
                : cleanedValue;

            const extKey = keyName === "All Fields" ? "" : `${keyName}:`;
            const extValue = keyName === "All Fields" ? value : `\`${value}\``;
            qlist.push(`${op}${extKey}${extValue}`);
          }
        } else if (
          DATE_RANGE_FIELD_DESC.year.includes(dKey) &&
          isDateRangeHasValidValue(d)
        ) {
          const { from, to } = dValue;
          if (from || to) {
            const fromDate = from ? `${from}-01-01` : jgiStartDate(); // JGI was created in 1997
            const toDate = to ? `${to}-12-31` : currentYYYYMMDD();
            const extValue = `\`[${fromDate} TO ${toDate}]\``;
            qlist.push(`${op}${keyName}:${extValue}`);
          }
        } else if (
          DATE_RANGE_FIELD_DESC.date.includes(dKey) &&
          isDateRangeHasValidValue(d)
        ) {
          const { from, to } = dValue;

          if (from || to) {
            const fromDate = toYYYYMMDD(from);
            const toDate = toYYYYMMDD(to, "to");
            const extValue = `\`[${fromDate} TO ${toDate}]\``;
            qlist.push(`${op}${keyName}:${extValue}`);
          }
        }
      });
    }
    // return qstring;
    return qlist.join("");
  };

  const doAdvancedQuery = () => {
    const qstr = queryValue();
    const searchParams = new URLSearchParams(); // blank search params
    searchParams.set("q", qstr);
    searchParams.set("t", "advanced");
    const { pathname } = window.location;
    const advString = searchParams.toString(); // use plain text in URL
    const toPath = pathname !== "/" ? `?${advString}` : `/search?${advString}`;
    history.replace(toPath);
    honeycomb.sendUiInteractionSpan("adv-search-performed", {
      search_value: toPath,
      field_count: searchMeta?.length,
    });
  };

  const disableSearchButton = () => {
    // if has date - valid date format
    const dateItems = [];
    const otherItems = [];
    searchMeta.forEach((d) => {
      if (DATE_RANGE_FIELD_DESC.all.includes(d.key)) {
        dateItems.push(d);
      } else {
        otherItems.push(d);
      }
    });

    const validDateItems = validDateRangeItems(dateItems);

    if (dateItems.length === 0) {
      // no date range
      return otherItems ? !otherItems[0].value : true;
    }

    if (otherItems.length === 0 && validDateItems.length === 0) {
      // only date range and no valid range items
      return true;
    }

    let disable = false;
    const BreakException = {};
    try {
      dateItems.forEach((d) => {
        const { value } = d;
        if (typeof value === "object") {
          const { from, to } = value;
          if ((from || to) && !isDateRangeHasValidValue(d)) {
            disable = true;
            throw BreakException;
          }
        }
      });
    } catch (e) {
      if (e !== BreakException) throw e;
    }
    return disable;
  };

  const searchButtonConfig = {
    onClick: doAdvancedQuery,
    variant: "contained",
    color: "primary",
    size: "large",
    className: "button",
    style: { width: 100, height: 34 },
    id: "adv-search-btn-id",
    disabled: disableSearchButton(),
    children: "Search",
  };

  const searchButton = getNativeButton({ ...searchButtonConfig });

  return (
    <div>
      <Drawer anchor="top" open={open} onClose={onClose}>
        <Box sx={{ width: "auto", padding: "20px 40px" }} role="presentation">
          <Grid
            container
            sx={{
              width: "auto",
              margin: 0,
              padding: 0,
            }}
          >
            <Grid item xs={12} md={10} lg={8} xl={7}>
              <Grid
                container
                sx={{
                  width: "auto",
                  margin: 0,
                  padding: 0,
                }}
                spacing={1}
              >
                {/* row 1 */}
                <Grid item md={11} sm={11} xs={11}>
                  <Box sx={{ display: "flex" }}>
                    <TextField
                      id="advanced-query-value"
                      variant="outlined"
                      disabled
                      fullWidth
                      sx={inputSizeStyle}
                      value={queryValue() || ""}
                    />
                  </Box>
                </Grid>
                <Grid item md={1} sm={1} xs={1} />
                {/* end row 1 */}

                {/* middle rows */}
                {getSearchParamUI()}
                {/* end middle rows */}

                {/* last row */}
                <Grid item md={3} sm={3} xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    {searchButton}
                    <ButtonMui
                      sx={{
                        textTransform: "none",
                        textDecoration: "underline",
                        marginLeft: 2,
                        color: theme.grey700,
                        fontSize: 14,
                      }}
                      onClick={() => {
                        setSearchMeta(
                          JSON.parse(JSON.stringify([{ value: "" }]))
                        );
                        setErrorMsg(null);
                      }}
                    >
                      <Typography noWrap>Clear All</Typography>
                    </ButtonMui>
                  </Box>
                </Grid>
                <Grid item md={9} sm={9} xs={6}>
                  <Box sx={{ display: "flex" }}>
                    {isLoading ? (
                      <LinearProgress
                        style={{
                          position: "relative",
                          top: 12,
                          height: 10,
                          marginTop: 2,
                          marginRight: 40,
                        }}
                      />
                    ) : errorMsg ? (
                      <Box
                        style={{
                          backgroundColor: "#FFE4DE",
                          marginRight: 40,
                          padding: "4px 20px 8px",
                        }}
                      >
                        <ErrorIcon
                          style={{
                            position: "relative",
                            top: 6,
                            color: "#FF0000",
                            marginRight: 4,
                          }}
                        />
                        {errorMsg}
                      </Box>
                    ) : null}
                    <Box sx={{ marginLeft: "auto", minWidth: 340 }}>
                      {learnMoreUI}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </div>
  );
};

export default AdvancedSearch;

AdvancedSearch.defaultProps = {
  error: null,
  isLoading: false,
  open: false,
  onClose: () => {},
  honeycomb: {
    sendUiInteractionSpan: () => {},
  },
  topRect: null,
  currentUser: {},
};

AdvancedSearch.propTypes = {
  error: PropTypes.shape(),
  isLoading: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  honeycomb: PropTypes.shape(),
  topRect: PropTypes.shape(),
  ldClient: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape(),
};
