import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { Box, Typography, AppBar, Paper, Table } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";

import Downloader from "../../Downloader/Downloader";
import GenomeTable from "../../GenomeTable/GenomeTable";
import SkeletonTable from "../../GenomeTable/SkeletonTable";

import { useFileAndPageContext } from "../../../context/FileAndPageContext";

import classes from "../TableContainer.module.css";
import { ShowOnScroll } from "../ScrollTransitions";

import { useSearchParams } from "../../../hooks/useSearchParams";
import { base as ldbase } from "../../../utils/LDFFUtils";

import SearchRelevanceSelector, {
  RESULTS_MOST_RELEVANT_INDEX,
  RESULTS_ALL_INDEX,
} from "./SearchRelevanceSelector";

import {
  getLocalStorageSelectedFiles,
  clearLocalStorageSelectedFiles,
  setLocalStorageLoseSelectionOK,
} from "../../../utils/LocalStorageUtils";

import Pagination, { newSearchParam } from "../Pagination/Pagination";
import { PAGE_SIZE_ARRAY } from "../Pagination/PagingUI";

import ConfirmChange, {
  CONFIRM_APPLY_FILTER,
  CONFIRM_APPLY_RELEVANCY,
} from "../../UI/InfoBoard/ConfirmChange";

import PageChangeWithSelectionDlg from "../Dialogs/PageChangeWithSelectionDlg";
import CartIsFullDialog from "../Dialogs/CartIsFullDlg";
import SearchProjectsDlg, { NEED_LOGIN } from "../Dialogs/SearchProjectsDlg";

import BentoBox from "../../BentoBox/BentoBox";

const null2zero = (val) => (val === null || val === undefined ? 0 : val);
const DEFAULT_PAGE_SIZE = PAGE_SIZE_ARRAY[0];

const SearchPage = (props) => {
  const {
    honeycomb,
    ldClient,
    currentUser,
    data,
    isLoading,
    isFetchingNextPage,
    handleApplyFilter,
    newSearchString,
    selection,
    setSelection,
    confirmApplyType,
    setConfirmApplyType,
    setSelectedFiles,
    addFilesToCart,
    storageOverflow,
    setStorageOverflow,
    mostRelevantSelectorIndex,
    setMostRelevantSelectorIndex,
    setMostRelevantSelectorIndex_,
  } = props;

  const { updateSelectedFiles, cartData } = useFileAndPageContext();

  const {
    include_private_data,
    searchParams,
    q: query,
    psize,
  } = useSearchParams();
  const myprojects = searchParams.get("f") === "project_id";

  const location = useLocation();
  const history = useHistory();

  const [pendingPage, setPendingPage] = useState(null);

  const [dataSize, setDataSize] = useState(0);

  const [searchInfoHeadText, setSearchInfoHeadText] = useState(null);

  const [advancedSearchOpen, setAdvancedSearchOpen] = useState(false);

  const advanced_search_ff = ldbase(ldClient, "advanced-search");

  const customParams = {};
  if (!psize) {
    customParams.x = DEFAULT_PAGE_SIZE;
  }

  // Get initial selected ID's from local storage
  /* istanbul ignore next */
  const getLocalStorage = () => {
    // only when logged on
    if (currentUser.name !== "Anonymous") {
      const sFiles = getLocalStorageSelectedFiles(); // JDP-1814 : stores list of {_id, organism_id, score, file_status, file_size, file_name}, same as selectedFiles
      if (sFiles && sFiles.length > 0) {
        return sFiles;
      }
    }

    return [];
  };

  // when URL not contain search; also needed by GenomeTable and SearchRelevanceSelector
  const freezeRelevantSelectorToShowAll = () =>
    mostRelevantSelectorIndex === 0 && location.pathname !== "search" && !query;

  // Update the globally shared context when selected changes;
  /* istanbul ignore next */
  useEffect(() => {
    if (typeof updateSelectedFiles === "function" && selection) {
      updateSelectedFiles(selection);
    }
  }, [selection]);

  const relevantData = () => {
    const sMin = data.relevant_min_score;

    /* istanbul ignore next */
    if (sMin === "none") return data.organisms;

    const organisms = data?.organisms.filter((o) => o.score.avg >= sMin);
    return organisms?.length === 0 ? [] : organisms;
  };

  const updateSummaryObj = () => {
    const organisms = relevantData();
    const curRel = organisms.length;

    let refInfo = data ? `${data.total} results found` : null;
    let allInfo = refInfo;
    /* istanbul ignore next */
    const relevantCnt =
      data.relevant_min_score === data.score_threshold
        ? data.prioritized_total
        : data.organisms.reduce(
            (init, org) =>
              org.score.avg >= data.relevant_min_score ? init + 1 : init,
            0
          );
    /* istanbul ignore next */
    const others = data ? data.total - relevantCnt : null;
    if (data) {
      const relPlural = relevantCnt > 1 || relevantCnt === 0 ? "s" : "";
      const othPlural = others > 1 || others === 0 ? "s" : "";
      /* istanbul ignore next */
      if (data.total > 0 && query) {
        refInfo = `${relevantCnt} "most relevant" result${relPlural} shown + ${others} additional result${othPlural} found`;
        allInfo = `${relevantCnt} "most relevant" result${relPlural} + ${others} additional result${othPlural} shown`;
      }
    }

    setSearchInfoHeadText({
      info: [refInfo, allInfo],
      relevantCnt,
      others,
      curRel,
    });
  };

  const relevancyMap = [
    {
      label: "Show Most Relevant Results",
      uiid: "show_most_relevant_results",
      name: "relevant",
      checked: mostRelevantSelectorIndex === 0,
    },
    {
      label: "Show All Results",
      uiid: "show_all_results",
      name: "all",
      checked: mostRelevantSelectorIndex !== 0,
    },
  ];

  useEffect(() => {
    if (data && !data.error) {
      setDataSize(
        mostRelevantSelectorIndex === 1
          ? null2zero(data?.total)
          : null2zero(data?.prioritized_total)
      );
      const selectionInStorage = getLocalStorage();
      /* istanbul ignore next */
      if (selectionInStorage?.length > 0) {
        setSelectedFiles(selectionInStorage);
      }
      clearLocalStorageSelectedFiles();

      if (freezeRelevantSelectorToShowAll()) {
        setMostRelevantSelectorIndex_(RESULTS_ALL_INDEX);
      }

      updateSummaryObj();
    }
  }, [data, mostRelevantSelectorIndex]);

  useEffect(() => {
    if (advanced_search_ff && data) {
      // if advanced search UI open, and data without error, close
      if (advancedSearchOpen && !data.error) {
        setAdvancedSearchOpen(false);
      } else if (!advancedSearchOpen && data.error) {
        setAdvancedSearchOpen(true);
      }
    }
  }, [data]);

  const relevanceSelectorConfig = {
    labels: relevancyMap,
    gap: 2,
    setIndex: setMostRelevantSelectorIndex,
    data,
    honeycomb,
    disabled: true,
  };

  let relevancySelectUI = (
    <SearchRelevanceSelector {...relevanceSelectorConfig} />
  );

  /* istanbul ignore next */
  if (data?.total > 0 && query) {
    if (query && data && !isLoading) {
      relevancySelectUI = (
        <SearchRelevanceSelector
          {...{
            ...relevanceSelectorConfig,
            disabled: freezeRelevantSelectorToShowAll(),
          }}
        />
      );
    }
  }

  /* istanbul ignore next */
  const handlePagingChange = (value, type) => {
    let doChange = true;
    if (selection?.length > 0) {
      if (type === "page") setPendingPage({ page: value });
      if (type === "size") setPendingPage({ size: value });
      doChange = false;
    }

    return doChange;
  };

  /* istanbul ignore next */
  const getAcceptConfirmationHandler = () => {
    let handler = null;
    if (confirmApplyType === CONFIRM_APPLY_FILTER) {
      handler = () => {
        handleApplyFilter(newSearchString, true);
        setConfirmApplyType("");
      };
    } else if (confirmApplyType === CONFIRM_APPLY_RELEVANCY) {
      handler = () => {
        setMostRelevantSelectorIndex(RESULTS_MOST_RELEVANT_INDEX, true);
        setConfirmApplyType("");
      };
    }
    return handler;
  };

  /* istanbul ignore next */
  const handlePageChangeDlgProceed = (action = "add") => {
    const pmeta = { ...pendingPage };
    setPendingPage(null);
    if (action === "add") addFilesToCart(selection);

    setSelection(null);

    /* istanbul ignore next */
    const newParam = pmeta.page
      ? newSearchParam(searchParams, {
          type: "page",
          value: pmeta.page,
          default: 1,
        })
      : pmeta.size
      ? newSearchParam(searchParams, {
          type: "size",
          value: pmeta.size,
          default: 10,
          total: dataSize,
        })
      : null;

    /* istanbul ignore next */
    if (newParam?.changed) history.push(newParam.value);
  };

  /* istanbul ignore next */
  let dialog = confirmApplyType ? (
    <ConfirmChange
      atype={confirmApplyType}
      acceptFn={getAcceptConfirmationHandler()}
      cancelFn={() => setConfirmApplyType("")}
      checkFn={(e) => {
        setLocalStorageLoseSelectionOK(e.target.checked);
      }}
    />
  ) : pendingPage !== null ? (
    <PageChangeWithSelectionDlg
      addToCartAndProceedBtnFn={() => {
        handlePageChangeDlgProceed("add");
      }}
      crossDismissBtnFn={() => {
        setPendingPage(null);
      }}
      dropFileAndProceedBtnFn={() => {
        handlePageChangeDlgProceed("drop");
      }}
    />
  ) : null;

  /* istanbul ignore next */
  if (!dialog && storageOverflow) {
    dialog = (
      <CartIsFullDialog
        closeFn={() => {
          honeycomb.sendUiInteractionSpan("cart-overflow", {
            ...storageOverflow,
            cart_file_count: cartData.organisms.reduce(
              (cnt, org) => cnt + org.files.length,
              0
            ),
          });
          setStorageOverflow(null);
        }}
      />
    );
  }

  const [acknowleged, setAcknowleged] = useState(false);

  /* istanbul ignore next */
  if (
    !dialog &&
    myprojects &&
    currentUser.name === "Anonymous" &&
    !acknowleged
  ) {
    dialog = (
      <SearchProjectsDlg
        reason={NEED_LOGIN}
        cancelBtnFn={() => {
          setAcknowleged(true);
          honeycomb.sendUiInteractionSpan("search-projects-anonymous");
        }}
      />
    );
  }

  const bentoBoxContainer = (
    <BentoBox
      {...props}
      advancedSearchOpen={advancedSearchOpen}
      setAdvancedSearch={advanced_search_ff ? setAdvancedSearchOpen : () => {}}
    />
  );

  const downloader = (
    <Downloader
      files={
        selection
          ? {
              files: selection,
              include_private_data: Boolean(include_private_data),
            }
          : null
      }
      isLoading={isLoading}
      {...props}
      scoreMin={mostRelevantSelectorIndex === 0 ? data?.relevant_min_score : 0}
      setSelectedFiles={setSelectedFiles}
      fullData={data}
    />
  );

  const filterRef = useRef(null);
  const downloaderRef = useRef(null);

  return (
    <>
      {/* JDP-1786: Do not HideOnScroll for this set of combo. 
              Also DO NOT omit -- ref is used by ShowOnScroll set + change height to this combo causes height detection in ShowOnScroll flips outcome
           */}
      <Paper
        className={classes.downloaderBox}
        elevation={1}
        ref={downloaderRef}
      >
        {downloader}
      </Paper>
      {/* The UI containing the Relevency selection and pagination */}
      <div>
        <div
          className={`${classes.searchInfoBox} SearchInfoPanel`}
          style={{ marginBottom: 10, marginTop: 10 }}
        >
          {relevancySelectUI}
          <Typography style={{ height: "100%", marginTop: 2, marginLeft: 20 }}>
            {searchInfoHeadText
              ? searchInfoHeadText.info[mostRelevantSelectorIndex]
              : null}
          </Typography>
          <div style={{ marginLeft: "auto" }}>
            <Pagination
              dataSize={dataSize}
              sizeChangeHandler={(value) => {
                return handlePagingChange(value, "size");
              }}
              pageChangeHandler={(value) => {
                return handlePagingChange(value, "page");
              }}
            />
          </div>
        </div>
        <Paper className={classes.filterBox} elevation={0} ref={filterRef}>
          {bentoBoxContainer}
        </Paper>
      </div>

      <ShowOnScroll {...props} refNode={downloaderRef}>
        <AppBar className={classes.appBar} style={{ margin: 0 }} elevation={1}>
          {downloader}
        </AppBar>
      </ShowOnScroll>

      <ShowOnScroll
        {...props}
        refNode={filterRef}
        unmountOnExit
        mountOnEnter
        offset={-50} // so will become sticky at half way disappearing under Add to Cart tool
      >
        <AppBar
          style={{ marginTop: 65 }}
          className={classes.appBar}
          elevation={1}
        >
          {bentoBoxContainer}
        </AppBar>
      </ShowOnScroll>

      <Box className={classes.tableBox}>
        {isLoading || isFetchingNextPage ? (
          <Table
            size="medium"
            aria-label="loading more rows"
            stickyHeader
            className={classes.table}
          >
            <SkeletonTable rowCount={5} />
          </Table>
        ) : (
          <GenomeTable
            {...props}
            selectedFiles={selection}
            setSelectedFiles={setSelectedFiles}
            data={data}
            isLoading={isLoading}
            isFetchingNextPage={isFetchingNextPage}
            setRelevantIndex={setMostRelevantSelectorIndex}
            disableRelevancy={freezeRelevantSelectorToShowAll()}
            cartData={cartData}
            addFilesToCart={addFilesToCart}
            showAll={mostRelevantSelectorIndex === 1}
          />
        )}
      </Box>

      {dialog}
    </>
  );
};

export default SearchPage;

SearchPage.defaultProps = {
  restoreRecords: null,
  honeycomb: null,
  ldClient: null,
  currentUser: {},
  setAppMessageID: () => {},
  searchCounts: 0,
  resetSearchCounts: () => {},
  data: null,
  isLoading: true,
  isFetchingNextPage: false,
  selection: null,
  storageOverflow: null,
};

SearchPage.propTypes = {
  restoreRecords: PropTypes.objectOf(Object),
  honeycomb: PropTypes.shape(),
  ldClient: PropTypes.shape(),
  currentUser: PropTypes.shape({
    name: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    keycloak: PropTypes.bool,
  }),
  setAppMessageID: PropTypes.func,
  searchCounts: PropTypes.number,
  resetSearchCounts: PropTypes.func,
  data: PropTypes.shape(),
  isLoading: PropTypes.bool,
  isFetchingNextPage: PropTypes.bool,
  handleApplyFilter: PropTypes.func.isRequired,
  newSearchString: PropTypes.string.isRequired,
  selection: PropTypes.arrayOf(PropTypes.shape()),
  setSelection: PropTypes.func.isRequired,
  confirmApplyType: PropTypes.string.isRequired,
  setConfirmApplyType: PropTypes.func.isRequired,
  setSelectedFiles: PropTypes.func.isRequired,
  addFilesToCart: PropTypes.func.isRequired,
  storageOverflow: PropTypes.bool,
  setStorageOverflow: PropTypes.func.isRequired,
  mostRelevantSelectorIndex: PropTypes.number.isRequired,
  setMostRelevantSelectorIndex: PropTypes.func.isRequired,
  setMostRelevantSelectorIndex_: PropTypes.func.isRequired,
};
