/* istanbul ignore file */
import React from "react";
import PropTypes from "prop-types";

import TextField from "@mui/material/TextField";

const ValueUI = ({ style, value, disabled, callback, placeholder }) => {
  const exProps = {};
  if (placeholder) {
    exProps.placeholder = placeholder;
  }
  return (
    <TextField
      error={!value && !disabled}
      variant="outlined"
      sx={style}
      disabled={disabled}
      fullWidth
      value={value}
      onChange={(event) => {
        callback(event.target.value);
      }}
      inputProps={{
        // make the font size same as the pulldownMene
        style: {
          fontSize: 14,
        },
      }}
      {...exProps}
    />
  );
};

export default ValueUI;

ValueUI.defaultProps = {
  value: "",
  disabled: true,
  placeholder: null,
};
ValueUI.propTypes = {
  style: PropTypes.shape().isRequired,
  value: PropTypes.string,
  callback: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};
