/* istanbul ignore file */
import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";

import { isValidDate } from "../../utils/StringUtils";

const DateUI = ({ style, valid, callback }) => {
  const [value, setValue] = React.useState("");

  const showErrorState = valid ? false : !isValidDate(value);
  const removeSlash = (val) => val.replaceAll("/", "");

  const _setValue = (val) => {
    setValue(val);
    callback(val);
  };
  return (
    <TextField
      error={showErrorState}
      variant="outlined"
      sx={style}
      placeholder="MM/DD/YYYY"
      fullWidth
      value={value}
      onChange={(event) => {
        let typed = event.target.value;
        if (typed.length > 10) {
          return;
        }
        if (
          typed[typed.length - 1] === "/" &&
          typed.length !== 3 &&
          typed.length !== 6
        ) {
          typed = typed.substring(0, typed.length - 1);
        }
        if (typed.indexOf("//") !== -1) {
          typed = typed.substring(0, typed.indexOf("//"));
        }
        if (!typed) {
          _setValue("");
          return;
        }
        const monthReg = /^\d{2}$/;
        const dateReg = /^\d{2}\/\d{2}$/;
        const val =
          monthReg.test(typed) || dateReg.test(typed) ? `${typed}/` : typed;

        if (/^[0-9]+$/.test(removeSlash(val))) {
          const num =
            typed.length <= 3
              ? parseInt(removeSlash(typed), 10)
              : parseInt(
                  removeSlash(typed.split("/")[typed.length <= 6 ? 1 : 2]),
                  10
                );
          if (
            (typed.length <= 3 && num < 13) ||
            (typed.length > 3 && typed.length <= 6 && num < 32) ||
            typed.length > 6
          ) {
            _setValue(val);
          }
        }
      }}
    />
  );
};

export default DateUI;

DateUI.propTypes = {
  style: PropTypes.shape().isRequired,
  callback: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
};
